@import './editor.css';
@import 'leaflet/dist/leaflet.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

#map {
    height: 100vh;
}

.leaflet-container {
    height: 600px;
    width: 100%;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}


.ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}





@keyframes ping {
    75%, 100% {
        transform: scale(2);
        opacity: 0;
    }
}



.pingIcon {
    animation: pingIcon 3s  infinite;
}



@keyframes pingIcon {

    50%, 75% {
        transform: scale(90%);
        opacity: .95;

    }
}


.animate-spin{
    animation: spin 2s infinite linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}